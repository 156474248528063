import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import gestion_etudiant from "../../Assets/Projects/gestion_etudiant.png";
import bank from "../../Assets/Projects/bank.png";
import client from "../../Assets/Projects/client.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gestion_etudiant}
              isBlog={false}
              title="Student Management"
              description="Through a dedicated site, students can easily access their information, register for classes, view their schedules, and perform other administrative tasks online." ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={client}
              isBlog={false}
              title="Customer Management"
              description="Thanks to this Applications, companies manage their customers, personalize their offers, and provide quality customer service. A dedicated site allows customers to access their account, place orders, track their activities and communicate with the company. " ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bank}
              isBlog={false}
              title="Transaction Management"
              description="This system records transaction details, such as amounts, dates, parties involved, and payment methods. A dedicated site provides users with secure access to view transaction history, perform searches, generate reports, and perform transaction-related actions. " ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://editor.soumya-jit.tech/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
