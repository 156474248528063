import React from "react";
import Github from "../About/Github";
import Techstack from "../About/Techstack";
import Toolstack from "../About/Toolstack";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillPhone, AiFillFacebook
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { HiPhone } from "react-icons/hi";
function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={12} className="home-about-description d-flex align-items-center flex-column">
            <h1 style={{ fontSize: "2.6em" }}>
              <span className="purple"> ABOUT </span> ME
            </h1>
            <Col md={10} className="home-about-body ">
              Hello! I'm a PHP and Laravel developer with a passion for building <b className="purple">robust and efficient web applications</b>. Allow me to introduce myself and provide you with some insights into my skills and experience.
              I have a strong foundation in PHP programming and have been working with Laravel, a popular PHP framework, <b className="purple">for several years.</b>🤷‍♂️
              <br />
              <br />I am fluent in classics like
              <i>
                <b className="purple"> PHP & Laravel,C,C++,Java,Vb,Html,css,Bootstrap </b>
              </i>

              <br />
              <br />
              If you are looking for a dedicated  <b className="purple"> PHP and Laravel developer</b> who can contribute to your project's success with <b className="purple">passion and expertise</b> , I would be thrilled to discuss how I can bring value to your team.

              Thank you for considering my profile, and I look forward to  the opportunity  <b className="purple">  to work with you! </b>
            </Col>
          </Col>
          <Col md={4} className="myAvtar d-none">
            <Tilt>
              <img src={myImg} className="img-fluid d-none" alt="avatar" />
            </Tilt>
          </Col>
        </Row>

        <Container fluid className="about-section ">
          <h1 className="project-heading">
            Professional <strong className="purple">Skillset </strong>
          </h1>

          <Techstack />

          <h1 className="project-heading">
            <strong className="purple">Tools</strong> I use
          </h1>
          <Toolstack />

          <Github />
        </Container>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>CONTACT US</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <div class="d-flex flex-row align-items-center justify-content-center">
              <li className="">
                <a
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <HiPhone /> </a>
              </li>
              <div class="d-flex flex-row 2 mx-2 icon-colour " style={{
                "gap": "5px", position: 'relative',
                display: 'inline-block',
                width: 'auto',
                height: '40px',
                textAlign: 'center',
                fontSize: '1.2em',
                lineHeight: '2em',
                background: 'rgba(255, 255, 255, 0.972)',
                borderRadius: '10px',
                transition: '0.5s',
                padding: '0px 5px',
              }}>
                <p class="m-auto">
                  Phone number  :
                </p>
                <p class="m-auto">
                  +25768503468
                </p>

              </div>

            </div>
            <ul className="home-about-social-links">



              <li className="social-icons">
                <a
                  href="https://instagram.com/jeanclaudenshimiyaremye"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>

              <li className="social-icons">
                <a
                  href="https://www.facebook.com/jeanclaude.nshimiyaremye"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  < AiFillFacebook />
                </a>
              </li>


            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
